import { InternalSecondNativeArticleCampaign, SecondNativeArticleCampaign } from '@/types/secondNativeArticleCampaign';
import { http } from '@/utils/http';
import { getSite } from 'lib/utils';
import getUrl from 'lib/utils/getUrl';

const calculateDaysBetweenDates = (startDate: Date, endDate: Date): number => {
  const differenceMs: number = Math.abs(endDate.getTime() - startDate.getTime());
  const daysDifference: number = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return daysDifference;
};

export const getNativeArticlesCandidates = async (articleCategory: string) => {
  const url = getUrl('api/native-article-campaigns');

  if (!url) {
    return [];
  }

  const brand = getSite();
  const response = await http.get<SecondNativeArticleCampaign[]>(url.href);

  const campaigns = (response?.data ?? []).reduce<InternalSecondNativeArticleCampaign[]>((acc, item) => {
    const isCorrectBrand = item.categories.includes(`${articleCategory}-${brand}`);
    if (!isCorrectBrand) return acc;

    const daysPassed = calculateDaysBetweenDates(new Date(item.startDate), new Date());
    const daysTotal = calculateDaysBetweenDates(new Date(item.startDate), new Date(item.endDate)) + 1; // +1 to include full last day

    const currentViews = item.goalMeasurement === 'pageViews' ? item.pageViews : item.uniquePageViews;

    const dailyGoal = (item.viewsGoal / daysTotal) * 1.2; // + 20% to make sure we reach the goal
    const expectedViewsUntilNow = daysPassed * dailyGoal;
    const isGoalReachedUntilNow = currentViews >= expectedViewsUntilNow;

    if (!isGoalReachedUntilNow) {
      const missingViewsForToday = expectedViewsUntilNow - currentViews;

      acc.push({
        ...item,
        missingViewsForToday,
      });
    }

    return acc;
  }, []);

  campaigns.sort((a, b) => b.missingViewsForToday - a.missingViewsForToday);

  return campaigns;
};
